import React from 'react';
import { dsnCN } from "../../../hooks/helper";

function MenuContent({ className }) {
    const socialData = [
        { link: "https://instagram.com/locgroup", name: "Instagram." },
        { link: "https://www.linkedin.com/company/loc-group-bilişim-a-s", name: "Linkedin." },
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Adresimiz</h5>
                    <p>
                        ALTINDAĞ MH 100.YIL BULVARI<br />
                        NO:12 CITYPLAZA, KAT:5 D:23,<br />
                        07000 MURATPAŞA/ANTALYA
                    </p>

                </div>
                <div className="nav-content mt-30">
                    <h5 className="sm-title-block mb-10">İletişim</h5>
                    <p className="links over-hidden mb-1">
                        <a className="link-hover" href="tel:+902429222022" data-hover-text="+90 (0242) 922 20 22">
                            (0242) 922 20 22
                        </a>
                    </p>
                    <p className="links over-hidden">
                        <a className="link-hover" href="mailto:info@loc.com.tr"
                            data-hover-text="info@dsngrid.com">info@loc.com.tr</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Bizi Takip Et</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;